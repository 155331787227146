<template>
  <div class="fileSelect">
    <div class="fileSelect-btn" @click="show = true">
      <slot></slot>
    </div>
    <el-dialog
      title="选择文件"
      :visible.sync="show"
      :before-close="handleBeforeClose"
      top="50vh"
    >
      <div class="fileSelect-list-container">
        <!-- 头部 -->
        <div class="fileSelect-list-header">
          <!-- 文件选择路径 -->
          <div class="fileSelect-path">
            <el-button
              type="text"
              class="fileSelect-path-prev"
              :disabled="!filePath.length"
              @click="checkOut"
              >返回上一级</el-button
            >
            <span class="splitLine"></span>
            <el-button
              type="text"
              class="fileSelect-path-all"
              @click="checkRoot"
              >全部文件</el-button
            >
            <!-- 当前路径 -->
            <template v-if="currentFile">
              <img
                class="fileSelect-path-current-icon"
                src="~a/workbench/arrow-right.png"
                alt=""
              />
              <span class="fileSelect-path-current">{{
                currentFile.name
              }}</span>
            </template>
          </div>
        </div>
        <!-- 列表 -->
        <div class="fileSelect-list">
          <el-checkbox-group v-if="fileList.length" v-model="checkedFile">
            <!-- 勾选框 -->
            <el-checkbox
              class="fileSelect-list-item"
              v-for="item in fileList"
              :key="item.id"
              :label="item.id"
            >
              <img
                class="fileSelect-list-item-icon"
                v-if="item.fileType === 'folder'"
                src="~a/workbench/folder.png"
                alt=""
              />
              <img
                class="fileSelect-list-item-icon"
                v-else
                src="~a/workbench/file.png"
                alt=""
              />
              <p @click.stop.prevent="checkIn(item)">{{ item.name }}</p>
            </el-checkbox>
          </el-checkbox-group>
          <div v-else class="noFile">
            <p class="noFile-tip">暂无文件</p>
            <router-link class="noFile-btn" to="/">前往上传文件</router-link>
          </div>
        </div>
      </div>
      <template #footer>
        <el-button @click="show = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'FileSelect',
  data() {
    return {
      show: false,
      fileList: [], // 文件列表
      checkedFile: [], // 已勾选的文件
      filePath: [], // 记录点击路径
      currentFile: '' // 当前层级文件信息
    };
  },
  created() {
    // 获取文件
    // this.fileList = [
    //   { id: 1, name: '2022-1-5w', fileType: 'folder' },
    //   { id: 2, name: '2022-2-5w', fileType: 'folder' },
    //   { id: 3, name: '2022-3-5w', fileType: 'file' },
    //   { id: 4, name: '2022-4-5w', fileType: 'file' }
    // ];
  },
  methods: {
    confirm() {
      this.$emit('confirm', this.checkedFile);
      this.handleBeforeClose();
    },
    // 查看上一级
    checkOut() {
      this.checkedFile = [];
      // 获取倒数二个元素
      const prevFile = this.filePath[this.filePath.length - 2];
      this.filePath.pop();
      this.currentFile = prevFile;
      // 查询上级文件
      this.handleSearch();
    },
    // 查看下一级
    checkIn(item) {
      this.checkedFile = [];
      this.filePath.push(item);
      this.currentFile = item;
      // 查询下级文件
      this.handleSearch(item.id);
    },
    // 查看根目录
    checkRoot() {
      this.checkedFile = [];
      this.filePath = [];
      this.currentFile = '';
      // 查询所有文件
      this.handleSearch();
    },
    // 搜索文件
    handleSearch() {},
    // 关闭前
    handleBeforeClose() {
      this.checkedFile = [];
      this.filePath = [];
      this.currentFile = '';
      this.show = false;
    }
  }
};
</script>

<style lang="less" scoped>
.fileSelect {
  &-list {
    height: 286px;
    overflow: auto;
    &-container {
      border: 1px solid #999999;
      border-radius: 4px;
    }
    &-header {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 40px;
      padding-left: 10px;
      border-bottom: 1px solid #999999;
    }
    &-item {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      width: 100%;
      height: 42px;
      padding-left: 24px;
      /deep/ .el-checkbox__label {
        display: flex;
        align-items: center;
        padding-left: 0;
        margin-left: 28px;
        color: #333;
        font-size: 18px;
      }
      &:hover {
        background-color: #e3ecff;
      }
      &-icon {
        width: 28px;
        height: 28px;
        margin-right: 10px;
      }
    }
  }
  &-path {
    display: flex;
    align-items: center;
    color: #507cff;
    font-size: 18px;
    &-prev {
      cursor: pointer;
    }
    &-all {
      margin-right: 6px;
      cursor: pointer;
    }
    &-current {
      margin-left: 6px;
      color: #666666;
      &-icon {
        width: 16px;
        height: 16px;
      }
    }
  }

  .splitLine {
    width: 1px;
    height: 20px;
    margin: 0 10px;
    background: #507cff;
  }

  // 没有文件时的提示
  .noFile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    &-tip {
      margin-bottom: 20px;
      color: #666;
      font-size: 1px;
    }
    &-btn {
      display: block;
      width: 143px;
      height: 33px;
      line-height: 33px;
      background: #507cff;
      border-radius: 4px;
      color: #fff;
      font-size: 16px;
    }
  }

  /deep/ .el-dialog {
    transform: translateY(-50%);
    width: 546px;
    &__header {
      padding: 12px;
      text-align: center;
    }
    &__title {
      color: #333;
      font-size: 20px;
      font-weight: bold;
    }
    &__body {
      padding-left: 40px;
      padding-right: 40px;
    }
    &__footer {
      padding-left: 40px;
      padding-right: 40px;
      .el-button {
        width: 120px;
        height: 40px;
        margin-left: 20px;
      }
    }
  }
}
</style>
