<template>
  <el-container>
    <el-header class="header" height="2.70833vw">
      <!-- 头部标题 -->
      <div class="header-left">
        <div class="header-btn">{{ this.title }}</div>
      </div>
    </el-header>
    <el-container class="main-container">
      <el-header class="main-header" height="4.16667vw">
        <!-- 发布步骤条 -->
        <el-steps class="steps" :active="step" align-center>
          <el-step title="选择数据发布类型"></el-step>
          <el-step title="添加数据新信息"></el-step>
          <el-step title="图层信息"></el-step>
          <el-step title="发布新数据服务"></el-step>
        </el-steps>
      </el-header>
      <el-main class="main">
        <!-- 各个步骤对应组件 -->
        <component
          :is="stepComponent"
          @next="handleNext"
          @releaseService="handleReleaseService"
          @prev="step--"
          :form="form"
        />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import ReleaseNewServiceStepOne from './ReleaseNewServiceStepOne';
import ReleaseNewServiceStepTwoOnlineData from './ReleaseNewServiceStepTwoOnlineData';
import ReleaseNewServiceStepTwoVectorData from './ReleaseNewServiceStepTwoVectorData';
import ReleaseNewServiceStepThree from './ReleaseNewServiceStepThree';
import ReleaseNewServiceStepFour from './ReleaseNewServiceStepFour';
export default {
  name: 'ReleaseNewService',
  data() {
    return {
      step: 1, // 不走
      // 第一步参数---start
      dataSourceType: 1,
      dataType: [],
      // 第一步参数---end
      // 第二步参数---start
      workSpace: '',
      datasetName: '',
      remark: '',
      link: '',
      username: '',
      password: '',
      useHtp: true,
      maxConcurrenceNum: 6,
      timeout: 30,
      validityTime: 60,
      fileType: 1,
      filePath: '',
      characterSet: '',
      // 第二步参数---end
      // 第三部参数--start
      layerName: '',
      layerRemark: '',
      crs: '',
      crsOptions: [],
      styleType: 1,
      style: '',
      styleOptions: [],
      styleName: '',
      nativeBoundingMinX: '',
      nativeBoundingMinY: '',
      nativeBoundingMaxX: '',
      nativeBoundingMaxY: '',
      coordBoundingMinX: '',
      coordBoundingMinY: '',
      coordBoundingMaxX: '',
      coordBoundingMaxY: '',
      // 第三部参数--end
      // 第四部参数--start
      serviceName: '',
      serviceType: []
      // 第四部参数--end
    };
  },
  computed: {
    title() {
      let title = '';
      switch (this.step) {
        case 1:
          title = '新建图层发布';
          break;
        case 2:
          {
            switch (this.dataSourceType) {
              case 3:
                title = '新建在线数据服务';
                break;
              default:
                title = '新建矢量数据服务';
                break;
            }
          }
          break;
        case 3:
          title = '编辑图层信息';
          break;
        case 4:
          title = '发布数据服务';
          break;
      }
      return title;
    },
    stepComponent() {
      let component = '';
      switch (this.step) {
        case 1:
          component = ReleaseNewServiceStepOne;
          break;
        case 2:
          {
            switch (this.dataSourceType) {
              case 3:
                component = ReleaseNewServiceStepTwoOnlineData;
                break;
              default:
                component = ReleaseNewServiceStepTwoVectorData;
                break;
            }
          }
          break;
        case 3:
          component = ReleaseNewServiceStepThree;
          break;
        case 4:
          component = ReleaseNewServiceStepFour;
          break;
      }
      return component;
    },
    form: {
      get() {
        let form = '';
        switch (this.step) {
          case 1:
            form = {
              dataSourceType: this.dataSourceType,
              dataType: this.dataType
            };
            break;
          case 2:
            switch (this.dataSourceType) {
              // 在线数据
              case 3:
                form = {
                  workSpace: this.workSpace,
                  datasetName: this.datasetName,
                  remark: this.remark,
                  link: this.link,
                  username: this.username,
                  password: this.password,
                  useHtp: this.useHtp,
                  maxConcurrenceNum: this.maxConcurrenceNum,
                  timeout: this.timeout,
                  validityTime: this.validityTime
                };
                break;
              // 矢量数据
              default:
                form = {
                  fileType: this.fileType,
                  filePath: this.filePath,
                  workSpace: this.workSpace,
                  datasetName: this.datasetName,
                  remark: this.remark,
                  characterSet: this.characterSet
                };
                break;
            }
            break;
          case 3:
            form = {
              layerName: this.layerName,
              layerRemark: this.layerRemark,
              crs: this.crs,
              style: this.style,
              nativeBoundingMinX: this.nativeBoundingMinX,
              nativeBoundingMinY: this.nativeBoundingMinY,
              nativeBoundingMaxX: this.nativeBoundingMaxX,
              nativeBoundingMaxY: this.nativeBoundingMaxY,
              coordBoundingMinX: this.coordBoundingMinX,
              coordBoundingMinY: this.coordBoundingMinY,
              coordBoundingMaxX: this.coordBoundingMaxX,
              coordBoundingMaxY: this.coordBoundingMaxY
            };
            break;
          case 4:
            form = {
              serviceName: this.serviceName,
              serviceType: this.serviceType
            };
            break;
        }
        return form;
      }
    }
  },
  methods: {
    // 上一步，下一步时，更新表单
    handleNext(form) {
      const step = this.step;
      if (step === 1) {
        const { dataSourceType, dataType } = form;
        this.dataSourceType = dataSourceType;
        this.dataType = dataType;
      }
      if (step === 2) {
        // 现在数据
        if (this.dataSourceType === 3) {
          const {
            workSpace,
            datasetName,
            remark,
            link,
            username,
            password,
            useHtp,
            maxConcurrenceNum,
            timeout,
            validityTime
          } = form;
          this.workSpace = workSpace;
          this.datasetName = datasetName;
          this.remark = remark;
          this.link = link;
          this.username = username;
          this.password = password;
          this.useHtp = useHtp;
          this.maxConcurrenceNum = maxConcurrenceNum;
          this.timeout = timeout;
          this.validityTime = validityTime;
        } else {
          // 矢量数据
          const {
            fileType,
            filePath,
            workSpace,
            datasetName,
            remark,
            characterSet
          } = form;
          this.fileType = fileType;
          this.filePath = filePath;
          this.workSpace = workSpace;
          this.datasetName = datasetName;
          this.remark = remark;
          this.characterSet = characterSet;
        }
      }
      if (step === 3) {
        const {
          layerName,
          layerRemark,
          crs,
          style,
          nativeBoundingMinX,
          nativeBoundingMinY,
          nativeBoundingMaxX,
          nativeBoundingMaxY,
          coordBoundingMinX,
          coordBoundingMinY,
          coordBoundingMaxX,
          coordBoundingMaxY
        } = form;
        this.layerName = layerName;
        this.layerRemark = layerRemark;
        this.crs = crs;
        this.style = style;
        this.nativeBoundingMinX = nativeBoundingMinX;
        this.nativeBoundingMinY = nativeBoundingMinY;
        this.nativeBoundingMaxX = nativeBoundingMaxX;
        this.nativeBoundingMaxY = nativeBoundingMaxY;
        this.coordBoundingMinX = coordBoundingMinX;
        this.coordBoundingMinY = coordBoundingMinY;
        this.coordBoundingMaxX = coordBoundingMaxX;
        this.coordBoundingMaxY = coordBoundingMaxY;
      }
      if (step === 4) {
        const { serviceName, serviceType } = form;
        this.serviceName = serviceName;
        this.serviceType = serviceType;
        return;
      }
      this.step++;
    },
    // 提交表单
    handleReleaseService() {
      // 跳转发布成功页面
      this.$router.push('/workbench/releaseService/success');
    }
  }
};
</script>

<style lang="less" scoped>
.main-header {
  justify-content: center;
  /deep/ .el-steps {
    box-sizing: border-box;
    width: 1176px;
    padding: 16px 68px 0 68px;
  }
  /deep/ .el-step {
    &__head {
      &.is-finish {
        color: #507cff;
        border-color: #507cff;
      }
      &.is-process,
      &.is-wait {
        color: #999999;
        border-color: #999999;
      }
    }
    // 标题
    &__title {
      font-weight: normal;
      &.is-finish {
        color: #507cff;
      }
      &.is-process,
      &.is-wait {
        color: #999999;
      }
    }
    // 步骤之间连线
    &__line {
      left: 60%;
      right: -40%;
    }
  }
}
</style>
