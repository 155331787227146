<template>
  <el-form class="form" label-position="top">
    <el-form-item label="服务名称">
      <el-input v-model="serviceName" maxlength="20" show-word-limit>
        <template slot="prefix">服务名称：</template>
      </el-input>
    </el-form-item>
    <el-form-item label="发布服务类型：（可多选）">
      <el-checkbox-group v-model="serviceType">
        <el-checkbox-button
          v-for="item in serviceTypes"
          :key="item.value"
          :label="item.value"
          >{{ item.label }}</el-checkbox-button
        >
      </el-checkbox-group>
    </el-form-item>
    <el-form-item class="bottom-btn">
      <el-button @click="$emit('prev')">上一步</el-button>
      <el-button type="primary" :disabled="disabled" @click="releaseService"
        >发布服务</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'ReleaseNewServiceStepFour',
  props: {
    form: Object
  },
  data() {
    return {
      serviceName: '',
      serviceType: [],
      serviceTypes: [
        { label: 'WMS', value: 1 },
        { label: 'WMTS', value: 2 },
        { label: 'WFS', value: 3 }
      ]
    };
  },
  computed: {
    disabled() {
      return !(this.serviceName && this.serviceType.length);
    }
  },
  created() {
    const { serviceName, serviceType } = this.form;
    this.serviceName = serviceName;
    this.serviceType = serviceType;
  },
  methods: {
    releaseService() {
      const form = {
        serviceName: this.serviceName,
        serviceType: this.serviceType
      };
      this.$emit('releaseService', form);
    }
  }
};
</script>
