<template>
  <el-form class="form" label-position="top">
    <el-form-item label="数据源类型">
      <el-radio-group v-model="dataSourceType" @change="handleChange">
        <el-radio-button
          v-for="item in dataSourceTypes"
          :key="item.value"
          :label="item.value"
          >{{ item.label }}
          <img
            class="checkedImg"
            v-if="dataSourceType === item.value"
            src="~a/workbench/checked.png"
            alt=""
          />
        </el-radio-button>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="数据类型" v-if="dataTypes.length">
      <el-checkbox-group v-model="dataType" @change="bindCheckBox">
        <el-checkbox
          v-for="item in dataTypes"
          :key="item.value"
          :label="item.value"
        >
          <span>{{ item.label }}</span
          ><span>{{ item.suffix }}</span>
        </el-checkbox>
      </el-checkbox-group>
    </el-form-item>
    <el-form-item class="bottom-btn">
      <el-button type="primary" :disabled="disabled" @click="next"
        >下一步</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'ReleaseNewServiceStepOne',
  props: {
    form: Object
  },
  data() {
    return {
      dataSourceType: 1,
      dataSourceTypes: [
        { label: '矢量数据', value: 1 },
        { label: '栅格数据', value: 2 },
        { label: '在线数据', value: 3 }
      ],
      dataType: []
    };
  },
  computed: {
    // 按钮禁用状态
    disabled() {
      return this.dataSourceType !== 3 && this.dataType.length < 1;
    },
    // 数据类型
    dataTypes() {
      let dataTypes = [];
      switch (this.dataSourceType) {
        case 1:
          dataTypes = [
            { label: 'Shapefile', suffix: 'ESRI(tm)Shapefile(*shp)', value: 1 },
            { label: 'Geopackage', suffix: '.gpkg', value: 2 },
            { label: 'GeojSON', suffix: '.json', value: 3 }
          ];
          break;
        case 2:
          dataTypes = [
            { label: 'IMG', suffix: '.png,.jpg', value: 1 },
            { label: 'TIF', suffix: '.tif', value: 2 }
          ];
          break;
      }
      return dataTypes;
    }
  },
  created() {
    // 初始化参数
    const { dataSourceType, dataType } = this.form;
    this.dataSourceType = dataSourceType;
    this.dataType = dataType;
  },
  methods: {
    // 设置数据类型只能单选
    bindCheckBox() {
      if (this.dataType.length > 1) {
        this.dataType.splice(0, 1);
      }
    },
    handleChange() {
      this.dataType = [];
    },
    next() {
      const form = {
        dataSourceType: this.dataSourceType,
        dataType: this.dataType
      };
      this.$emit('next', form);
    }
  }
};
</script>

<style lang="less" scoped>
.form {
  // 单选框样式
  /deep/ .el-radio {
    &-button {
      margin-right: 40px;
      &__inner {
        width: 156px;
        height: 52px;
        line-height: 52px;
        padding: 0;
        background: #ffffff;
        border: 1px solid #d6e0ff;
        border-radius: 6px !important;
        box-shadow: none;
        color: #333;
        font-size: 18px;
        .checkedImg {
          position: absolute;
          top: -1px;
          right: -1px;
          width: 39px;
          height: 39px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      // 选中时样式
      &.is-active {
        .el-radio-button__inner {
          border-color: #507cff;
        }
      }
    }
  }
  // 多选框样式
  /deep/ .el-checkbox {
    display: flex;
    align-items: center;
    height: 62px;
    padding: 0 20px 0 30px;
    margin-right: 0 !important;
    border-bottom: 1px solid #b2c5ff;
    &:last-child {
      border-bottom: none;
    }
    &__label {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      padding-left: 0;
    }
    &-group {
      border: 1px solid #507cff;
      border-radius: 6px;
    }
  }
}
</style>
