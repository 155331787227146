<template>
  <el-form class="form" label-position="top">
    <el-form-item label="数据文件">
      <el-radio-group v-model="fileType">
        <el-radio :label="1">文件管理中选择</el-radio>
        <el-radio :label="2">在线文件</el-radio>
      </el-radio-group>
      <div class="form-item-bottom">
        <template v-if="fileType === 1">
          <FileSelect @confirm="handleSelectFileConfirm">
            <div class="upload">
              <img class="upload-icon" src="~a/workbench/upload.png" alt="" />
              <span class="upload-text">选择文件（.shp）</span>
            </div>
          </FileSelect>
        </template>
        <template v-else>
          <el-input
            v-model="filePath"
            placeholder="输入链接地址，必须是已授权的链接地址"
          />
        </template>
      </div>
    </el-form-item>
    <el-row type="flex" justify="space-between">
      <!-- 工作空间 -->
      <el-col :span="11">
        <el-form-item label="工作空间">
          <el-select v-model="workSpace" :popper-append-to-body="false">
            <el-option
              v-for="option in workSpaceOptions"
              :key="'workSpace_' + option.id"
              :label="option.name"
              :value="option.id"
            />
            <button
              type="button"
              class="createWoekSpaceBtn"
              @click="createWoekSpace"
            >
              新建工作空间
            </button>
          </el-select>
        </el-form-item>
      </el-col>
      <!-- 数据集名称 -->
      <el-col :span="11">
        <el-form-item label="数据集名称">
          <el-input
            placeholder="请输入数据集名称"
            v-model="datasetName"
            :maxlength="20"
            show-word-limit
          />
        </el-form-item>
      </el-col>
    </el-row>
    <!-- 数据说明 -->
    <el-form-item label="数据说明">
      <el-input
        v-model="remark"
        type="textarea"
        placeholder="数据说明"
        :maxlength="200"
        show-word-limit
        :rows="5"
        resize="none"
      />
    </el-form-item>
    <el-row type="flex" justify="space-between">
      <!-- 选择字符集 -->
      <el-col :span="11">
        <el-form-item label="选择字符集">
          <el-select v-model="characterSet" :popper-append-to-body="false">
            <el-option
              v-for="option in characterSetOptions"
              :key="'characterSet_' + option.id"
              :label="option.name"
              :value="option.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item class="bottom-btn">
      <el-button @click="$emit('prev')">上一步</el-button>
      <el-button type="primary" :disabled="disabled" @click="next"
        >下一步</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import FileSelect from 'c/workbench/FileSelect';
export default {
  name: 'ReleaseNewServiceStepTwoVectorData',
  components: {
    FileSelect
  },
  props: {
    form: Object
  },
  data() {
    return {
      fileType: '',
      filePath: '',
      workSpace: '',
      workSpaceOptions: [],
      datasetName: '',
      remark: '',
      characterSet: '',
      characterSetOptions: [],
      rules: {
        filePath: [
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    // 按钮禁用状态
    disabled() {
      return !(
        this.workSpace &&
        this.datasetName &&
        this.remark &&
        this.fileType &&
        this.filePath &&
        this.characterSet
      );
    }
  },
  created() {
    // 初始化参数
    const { fileType, filePath, workSpace, datasetName, remark, characterSet } =
      this.form;
    this.fileType = fileType;
    this.filePath = filePath;
    this.workSpace = workSpace;
    this.datasetName = datasetName;
    this.remark = remark;
    this.characterSet = characterSet;

    // 获取工作空间
    this.workSpaceOptions = [
      { name: '系统默认', id: 1 },
      { name: '工作空间1', id: 2 },
      { name: '工作空间2', id: 3 }
    ];
    // 获取字符集
    this.characterSetOptions = [
      { name: 'ISO-859-1', id: 1 },
      { name: 'GB18030', id: 2 },
      { name: 'UTF-8', id: 3 }
    ];
  },
  methods: {
    // 文件选择
    handleSelectFileConfirm(files) {
      console.log(files);
    },
    // 新建工作空间
    createWoekSpace() {
      this.$prompt('', '新建工作空间', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'prompt'
      })
        .then(({ value }) => {
          this.workSpaceOptions.push({
            name: value,
            id: this.$uuid()
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });
    },
    // 下一步
    next() {
      const form = {
        fileType: this.fileType,
        filePath: this.filePath,
        workSpace: this.workSpace,
        datasetName: this.datasetName,
        remark: this.remark,
        characterSet: this.characterSet
      };
      this.$emit('next', form);
    }
  }
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 686px;
  height: 82px;
  background: #ffffff;
  border: 1px solid #507cff;
  border-radius: 6px;
  &-icon {
    width: 32px;
    height: 32px;
    margin-bottom: 6px;
  }
  &-text {
    color: #666;
    font-size: 16px;
  }
}

.createWoekSpaceBtn {
  width: 100%;
  height: 43px;
  line-height: 42px;
  cursor: pointer;
  color: #507cff;
  font-size: 16px;
  border-top: 1px solid #d6d6d6;
}

/deep/.el-select-dropdown {
  margin: -1px 0 0 -5px;
  border-radius: 3px;
  overflow: hidden;
}
//去掉下拉框小三角
/deep/.el-popper[x-placement^='bottom'] .popper__arrow::after {
  border-bottom-color: transparent !important;
}
/deep/.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border-style: none !important;
}
</style>
