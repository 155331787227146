<template>
  <el-form class="form" label-position="top">
    <!-- 图层名称 -->
    <el-form-item label="图层名称">
      <el-input
        v-model="layerName"
        placeholder="图层名称"
        maxlength="20"
        show-word-limit
      />
    </el-form-item>
    <!-- 图层说明 -->
    <el-form-item label="图层说明">
      <el-input
        v-model="layerRemark"
        type="textarea"
        placeholder="图层说明"
        :maxlength="200"
        show-word-limit
        :rows="5"
        resize="none"
      />
    </el-form-item>
    <!-- 定义CRS -->
    <el-form-item label="定义CRS">
      <el-select v-model="crs" :popper-append-to-body="false">
        <el-option
          v-for="option in crsOptions"
          :key="'crs_' + option.id"
          :value="'crs_' + option.id"
          :label="option.name"
        />
      </el-select>
    </el-form-item>
    <!-- 选择样式 -->
    <el-form-item label="选择样式">
      <el-radio-group v-model="styleType">
        <el-radio :label="1">选择默认样式</el-radio>
        <el-radio :label="2">代码编辑样式</el-radio>
      </el-radio-group>
      <div class="form-item-bottom">
        <el-select
          v-if="styleType === 1"
          v-model="style"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="option in styleOptions"
            :key="'style_' + option.id"
            :value="'style_' + option.id"
            :label="option.name"
          />
        </el-select>
        <template v-else>
          <!-- 样式名称 -->
          <el-input v-model="styleName" placeholder="样式名称" />
          <div class="textarea-wrap">
            <el-input
              type="textarea"
              v-model="style"
              placeholder="样式"
              resize="none"
              :rows="5"
            />
            <div class="textarea-bottom">
              <el-button class="textarea-btn">取消</el-button>
              <el-button class="textarea-btn" type="primary">保存</el-button>
            </div>
          </div>
        </template>
      </div>
    </el-form-item>
    <el-row class="form-item-wrap">
      <p class="form-item-label">边距设置</p>
      <el-button
        type="primary"
        class="getBoundingFromDataBtn"
        @click="getBoundingFromData"
        >从数据中获取</el-button
      >
      <div class="form-item-inner">
        <p class="form-item-inner-label">Native Bounding Box</p>
        <el-row type="flex" justify="space-between" :gutter="20">
          <el-col :span="6"
            ><el-form-item class="inline" label="最小X"
              ><el-input v-model="nativeBoundingMinX" type="number"
            /></el-form-item>
          </el-col>
          <el-col :span="6"
            ><el-form-item class="inline" label="最小Y"
              ><el-input v-model="nativeBoundingMinY" type="number"
            /></el-form-item>
          </el-col>
          <el-col :span="6"
            ><el-form-item class="inline" label="最大X"
              ><el-input v-model="nativeBoundingMaxX" type="number"
            /></el-form-item>
          </el-col>
          <el-col :span="6"
            ><el-form-item class="inline" label="最大Y"
              ><el-input
                v-model="nativeBoundingMaxY"
                type="number" /></el-form-item
          ></el-col>
        </el-row>
      </div>
      <div class="form-item-inner">
        <p class="form-item-inner-label">纬度/经度边框</p>
        <el-row type="flex" justify="space-between" :gutter="20">
          <el-col :span="6"
            ><el-form-item class="inline" label="最小X"
              ><el-input v-model="coordBoundingMinX" type="number"
            /></el-form-item>
          </el-col>
          <el-col :span="6"
            ><el-form-item class="inline" label="最小Y"
              ><el-input v-model="coordBoundingMinY" type="number"
            /></el-form-item>
          </el-col>
          <el-col :span="6"
            ><el-form-item class="inline" label="最大X"
              ><el-input v-model="coordBoundingMaxX" type="number"
            /></el-form-item>
          </el-col>
          <el-col :span="6"
            ><el-form-item class="inline" label="最大Y"
              ><el-input
                v-model="coordBoundingMaxY"
                type="number" /></el-form-item
          ></el-col>
        </el-row>
      </div>
    </el-row>
    <el-form-item class="bottom-btn">
      <el-button @click="$emit('prev')">上一步</el-button>
      <el-button type="primary" :disabled="disabled" @click="next"
        >下一步</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'ReleaseNewServiceStepThree',
  props: {
    form: Object
  },
  data() {
    return {
      layerName: '',
      layerRemark: '',
      crs: '',
      crsOptions: [],
      styleType: 1,
      style: '',
      styleOptions: [],
      styleName: '',
      nativeBoundingMinX: '',
      nativeBoundingMinY: '',
      nativeBoundingMaxX: '',
      nativeBoundingMaxY: '',
      coordBoundingMinX: '',
      coordBoundingMinY: '',
      coordBoundingMaxX: '',
      coordBoundingMaxY: ''
    };
  },
  computed: {
    // 按钮禁用状态
    disabled() {
      return !(
        this.layerName &&
        this.layerRemark &&
        this.crs &&
        this.style &&
        this.nativeBoundingMinX &&
        this.nativeBoundingMinY &&
        this.nativeBoundingMaxX &&
        this.nativeBoundingMaxY &&
        this.coordBoundingMinX &&
        this.coordBoundingMinY &&
        this.coordBoundingMaxX &&
        this.coordBoundingMaxY
      );
    }
  },
  created() {
    // 初始化参数
    const {
      layerName,
      layerRemark,
      crs,
      style,
      nativeBoundingMinX,
      nativeBoundingMinY,
      nativeBoundingMaxX,
      nativeBoundingMaxY,
      coordBoundingMinX,
      coordBoundingMinY,
      coordBoundingMaxX,
      coordBoundingMaxY
    } = this.form;
    this.layerName = layerName;
    this.layerRemark = layerRemark;
    this.crs = crs;
    this.style = style;
    this.nativeBoundingMinX = nativeBoundingMinX;
    this.nativeBoundingMinY = nativeBoundingMinY;
    this.nativeBoundingMaxX = nativeBoundingMaxX;
    this.nativeBoundingMaxY = nativeBoundingMaxY;
    this.coordBoundingMinX = coordBoundingMinX;
    this.coordBoundingMinY = coordBoundingMinY;
    this.coordBoundingMaxX = coordBoundingMaxX;
    this.coordBoundingMaxY = coordBoundingMaxY;

    this.crsOptions = [
      { name: 'crs1', id: 1 },
      { name: 'crs2', id: 2 },
      { name: 'crs3', id: 3 }
    ];
    this.styleOptions = [
      { name: 'style1', id: 1 },
      { name: 'style2', id: 2 },
      { name: 'style3', id: 3 }
    ];
  },
  methods: {
    getBoundingFromData() {},
    next() {
      const form = {
        layerName: this.layerName,
        layerRemark: this.layerRemark,
        crs: this.crs,
        style: this.style,
        nativeBoundingMinX: this.nativeBoundingMinX,
        nativeBoundingMinY: this.nativeBoundingMinY,
        nativeBoundingMaxX: this.nativeBoundingMaxX,
        nativeBoundingMaxY: this.nativeBoundingMaxY,
        coordBoundingMinX: this.coordBoundingMinX,
        coordBoundingMinY: this.coordBoundingMinY,
        coordBoundingMaxX: this.coordBoundingMaxX,
        coordBoundingMaxY: this.coordBoundingMaxY
      };
      this.$emit('next', form);
    }
  }
};
</script>

<style lang="less" scoped>
.form-item-wrap {
  position: relative;
  .getBoundingFromDataBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 120px;
    height: 32px;
  }

  .inline /deep/ .el-form-item {
    &__label {
      width: 150px;
      font-size: 18px;
    }
    &__content {
      .el-input {
        &__inner {
          height: 32px;
          line-height: 32px;
          border-radius: 6px;
        }
      }
    }
  }
}

// 自定义样式输入框
.textarea {
  &-wrap {
    position: relative;
    margin-top: 10px;
  }
  &-bottom {
    position: absolute;
    bottom: 10px;
    right: 20px;
  }
  &-btn {
    width: 88px;
    height: 34px;
    margin-left: 20px;
    border-radius: 6px;
  }
}

/deep/.el-select-dropdown {
  margin: -1px 0 0 -5px;
  border-radius: 3px;
  overflow: hidden;
}
//去掉下拉框小三角
/deep/.el-popper[x-placement^='bottom'] .popper__arrow::after {
  border-bottom-color: transparent !important;
}
/deep/.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border-style: none !important;
}
</style>
