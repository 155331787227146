<template>
  <el-form class="form" label-position="top">
    <el-row type="flex" justify="space-between">
      <!-- 工作空间 -->
      <el-col :span="11">
        <el-form-item label="工作空间">
          <el-select v-model="workSpace" :popper-append-to-body="false">
            <el-option
              v-for="option in workSpaceOptions"
              :key="'workSpace_' + option.id"
              :label="option.name"
              :value="option.id"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <!-- 数据集名称 -->
      <el-col :span="11">
        <el-form-item label="数据集名称">
          <el-input
            placeholder="请输入数据集名称"
            v-model="datasetName"
            :maxlength="20"
            show-word-limit
          />
        </el-form-item>
      </el-col>
    </el-row>
    <!-- 数据说明 -->
    <el-form-item label="数据说明">
      <el-input
        v-model="remark"
        type="textarea"
        placeholder="数据说明"
        :maxlength="200"
        show-word-limit
        :rows="5"
        resize="none"
      />
    </el-form-item>
    <el-row type="flex" justify="space-between">
      <!-- 数据链接URL -->
      <el-col :span="7">
        <el-form-item label="数据链接URL">
          <el-input placeholder="请输入URL" v-model="link" />
          <el-checkbox class="checkbox" v-model="useHtp"
            >使用HTP链接池</el-checkbox
          >
        </el-form-item>
      </el-col>
      <!-- 用户名 -->
      <el-col :span="7">
        <el-form-item>
          <template #label>
            <div class="customFormLabel">
              <span>用户名</span>
              <el-tooltip
                placement="bottom-start"
                content="访问数据链接需要输入的用户名"
              >
                <img src="~a/workbench/tip.png" alt="" />
              </el-tooltip>
            </div>
          </template>
          <el-input placeholder="用户名" v-model="username" />
        </el-form-item>
      </el-col>
      <!-- 密码 -->
      <el-col :span="7">
        <el-form-item label="密码">
          <template #label>
            <div class="customFormLabel">
              <span>密码</span>
              <el-tooltip
                placement="bottom-start"
                content="访问数据链接需要输入的密码"
              >
                <img src="~a/workbench/tip.png" alt="" />
              </el-tooltip>
            </div>
          </template>
          <el-input placeholder="密码" v-model="password" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row type="flex" justify="space-between">
      <!-- 最大并发链接数 -->
      <el-col :span="7">
        <el-form-item label="最大并发链接数">
          <el-input placeholder="最大并发链接数" v-model="maxConcurrenceNum" />
        </el-form-item>
      </el-col>
      <!-- 链接超时（秒） -->
      <el-col :span="7">
        <el-form-item label="链接超时（秒）">
          <el-input placeholder="链接超时（秒）" v-model="validityTime" />
        </el-form-item>
      </el-col>
      <!-- 读超时（秒） -->
      <el-col :span="7">
        <el-form-item label="读超时（秒）">
          <el-input placeholder="读超时（秒）" v-model="timeout" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item class="bottom-btn">
      <el-button @click="$emit('prev')">上一步</el-button>
      <el-button type="primary" :disabled="disabled" @click="next"
        >下一步</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'ReleaseNewServiceStepTwoOnlineData',
  props: {
    form: Object
  },
  data() {
    return {
      workSpace: '',
      workSpaceOptions: [],
      datasetName: '',
      remark: '',
      link: '',
      username: '',
      password: '',
      useHtp: false,
      maxConcurrenceNum: '',
      timeout: '',
      validityTime: ''
    };
  },
  computed: {
    // 按钮禁用状态
    disabled() {
      return !(
        this.workSpace &&
        this.datasetName &&
        this.remark &&
        this.link &&
        this.username &&
        this.password &&
        this.useHtp &&
        this.maxConcurrenceNum &&
        this.timeout &&
        this.validityTime
      );
    }
  },
  created() {
    // 初始化参数
    const {
      workSpace,
      datasetName,
      remark,
      link,
      username,
      password,
      useHtp,
      maxConcurrenceNum,
      timeout,
      validityTime
    } = this.form;
    this.workSpace = workSpace;
    this.datasetName = datasetName;
    this.remark = remark;
    this.link = link;
    this.username = username;
    this.password = password;
    this.useHtp = useHtp;
    this.maxConcurrenceNum = maxConcurrenceNum;
    this.timeout = timeout;
    this.validityTime = validityTime;

    // 获取工作空间
    this.workSpaceOptions = [
      { name: '222', id: 1 },
      { name: '432143', id: 2 },
      { name: '4123', id: 3 }
    ];
  },
  methods: {
    next() {
      const form = {
        workSpace: this.workSpace,
        datasetName: this.datasetName,
        remark: this.remark,
        link: this.link,
        username: this.username,
        password: this.password,
        useHtp: this.useHtp,
        maxConcurrenceNum: this.maxConcurrenceNum,
        timeout: this.timeout,
        validityTime: this.validityTime
      };
      this.$emit('next', form);
    }
  }
};
</script>

<style lang="less" scoped>
.checkbox {
  margin-top: 10px;
  line-height: initial;
}
.customFormLabel {
  display: flex;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}
</style>
